export type RemoteData<E, A> = NotAsked | Loading | Failure<E> | Success<A>

export interface NotAsked {
  _tag: "NotAsked"
}
export const NotAsked: RemoteData<never, never> = { _tag: "NotAsked" }

export const isNotAsked = <E, A>(rd: RemoteData<E, A>): rd is NotAsked =>
  rd._tag === "NotAsked"

export interface Loading {
  _tag: "Loading"
}
export const Loading: RemoteData<never, never> = { _tag: "Loading" }

export const isLoading = <E, A>(rd: RemoteData<E, A>): rd is Loading =>
  rd._tag === "Loading"

export interface Success<A> {
  _tag: "Success"
  result: A
}

export function Success<A>(a: A): RemoteData<never, A> {
  return {
    _tag: "Success",
    result: a,
  }
}

export const isSuccess = <E, A>(rd: RemoteData<E, A>): rd is Success<A> =>
  rd._tag === "Success"

export interface Failure<E> {
  _tag: "Failure"
  reason: E
}

export function Failure<E>(e: E): RemoteData<E, never> {
  return {
    _tag: "Failure",
    reason: e,
  }
}

export const isFailure = <E, A>(rd: RemoteData<E, A>): rd is Failure<E> =>
  rd._tag === "Failure"

export function hasLoaded<E, A>(rd: RemoteData<E, A>): rd is Failure<E> | Success<A> {
  return rd._tag !== "NotAsked" && rd._tag !== "Loading"
}

export function caseOf<E, A, T>(
  rd: RemoteData<E, A>,
  fallback: T,
  withSuccess: (result: A) => T,
  withFailure: (reason: E) => T
) {
  switch (rd._tag) {
    case "NotAsked":
      return fallback
    case "Loading":
      return fallback
    case "Success":
      return withSuccess(rd.result)
    case "Failure":
      return withFailure(rd.reason)
  }
}

export function map<E, A, B>(
  rd: RemoteData<E, A>,
  f: (a: A) => B
): RemoteData<E, B> {
  if (isSuccess(rd)) {
    return Success(f(rd.result))
  } else {
    return rd
  }
}
