import {
  calculatorApiToken,
  mkInitialProduct,
} from "../../shared-components/Calculators/api"
import {
  InitialPeriod,
  Percentage,
  Pence,
  Pounds,
  Years,
  mkPercentage,
  mkPence,
  mkPounds,
} from "../../shared-components/Calculators/types"
import { Country } from "./types"

export interface BorrowingAmountsRequest {
  income: Pounds
  deposit: Pounds
}

export interface BorrowingAmountsResponse {
  maxBorrowingAmount: Pounds
  maxBorrowingAmountForApi: Pounds
  maxPropertyValue: Pounds
  ssmipBorrowingAmount: Pounds
}

export const getBorrowingAmounts = async (
  requestData: BorrowingAmountsRequest
): Promise<BorrowingAmountsResponse> => {
  const { income, deposit } = requestData
  const maxPotentialBorrowingAmount = income.value * 4.5
  const reliableBorrowingAmount = income.value * 3.75

  const res = await fetch(`/api/mip/indicative-borrowing-amount`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      income: income.value,
      deposit: deposit.value,
    }),
  })

  const body = await res.text()

  if (res.status === 400 && body === "Input out of range") {
    // We couldn't get a SSMIP amount -- probably the inputs are too small.
    // Fall back to non-SSMIP values.

    return {
      maxBorrowingAmount: mkPounds(maxPotentialBorrowingAmount),
      maxBorrowingAmountForApi: mkPounds(reliableBorrowingAmount),
      // use reliableBorrowingAmount instead of maxPotentialBorrowingAmount as that
      // is a stretch number and not necessarily attainable
      maxPropertyValue: mkPounds(reliableBorrowingAmount + deposit.value),
      ssmipBorrowingAmount: mkPounds(reliableBorrowingAmount),
    }
  } else if (res.status !== 200) {
    throw new Error(
      "Error calling /api/mip/indicative-borrowing-amount: " +
        res.status +
        "; " +
        body
    )
  }

  const { borrowingAmount: ssmipBorrowingAmount } = JSON.parse(body)

  return {
    maxBorrowingAmount: mkPounds(maxPotentialBorrowingAmount),
    maxBorrowingAmountForApi: mkPounds(ssmipBorrowingAmount),
    maxPropertyValue: mkPounds(ssmipBorrowingAmount + deposit.value),
    ssmipBorrowingAmount: mkPounds(ssmipBorrowingAmount),
  }
}

export class NoResultsError extends Error {
  constructor(msg: string) {
    super(msg)
    this.name = "NoResultsError"
  }
}

export interface NewMortgageMonthlyPaymentsRequest {
  propertyValue: Pounds
  borrowingAmount: Pounds
  initialPeriod: InitialPeriod
  mortgageTerm: Years
}

export interface NewMortgageMonthlyPaymentsResponse {
  monthlyPayments: Pounds
}

export const getNewMortgageMonthlyPayments = async (
  requestData: NewMortgageMonthlyPaymentsRequest
): Promise<NewMortgageMonthlyPaymentsResponse> => {
  const { propertyValue, borrowingAmount, initialPeriod, mortgageTerm } =
    requestData

  const res = await fetch(`/api/calculator/v2/monthly-payments`, {
    method: "POST",
    headers: {
      Authorization: calculatorApiToken,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      propertyValue: propertyValue.value,
      borrowingAmount: borrowingAmount.value,
      mortgageTerm: mortgageTerm.value,
      initialProduct: mkInitialProduct(initialPeriod, "Fixed"),
      newOrRemortgage: "NewMortgage",
    }),
  })

  if (res.status !== 200) {
    const body = await res.text()

    if (res.status === 400 && body === `"ZeroSourcingResults"`) {
      throw new NoResultsError("No sourcing results")
    } else {
      throw new Error(
        "Error calling /api/calculator/v2/monthly-payments: " +
          res.status +
          "; " +
          body
      )
    }
  }

  const json = await res.json()
  const { monthlyPayments } = json
  return {
    monthlyPayments: mkPounds(monthlyPayments),
  }
}

export interface RemortgageMonthlyPaymentsRequest {
  borrowingAmount: Pounds
  propertyValue: Pounds
  initialPeriod: InitialPeriod
  mortgageTerm: Years
  currentMonthlyPayments: Pounds
}

export interface RemortgageMonthlyPaymentsResponse {
  potentialMonthlyPayments: Pounds
  savingsOverProductTerm: Pounds
  equity: Percentage
}

export const getRemortgageMonthlyPayments = async (
  requestData: RemortgageMonthlyPaymentsRequest
): Promise<RemortgageMonthlyPaymentsResponse> => {
  const {
    borrowingAmount,
    propertyValue,
    initialPeriod,
    mortgageTerm,
    currentMonthlyPayments,
  } = requestData

  const res = await fetch(`/api/calculator/v2/monthly-payments`, {
    method: "POST",
    headers: {
      Authorization: calculatorApiToken,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      propertyValue: propertyValue.value,
      borrowingAmount: borrowingAmount.value,
      mortgageTerm: mortgageTerm.value,
      initialProduct: mkInitialProduct(initialPeriod, "Fixed"),
      newOrRemortgage: "Remortgage",
    }),
  })

  if (res.status !== 200) {
    const body = await res.text()

    if (res.status === 400 && body === `"ZeroSourcingResults"`) {
      throw new NoResultsError("No sourcing results")
    } else {
      throw new Error(
        "Error calling /api/calculator/v2/monthly-payments: " +
          res.status +
          "; " +
          body
      )
    }
  }

  const json = await res.json()
  const { monthlyPayments } = json

  const productTermMonths =
    12 *
    (() => {
      switch (initialPeriod) {
        case "2":
          return 2
        case "3":
          return 3
        case "5":
          return 5
        case "10":
          return 10
        case "FullTerm":
          return mortgageTerm.value
      }
    })()
  const savingsOverProductTerm =
    (currentMonthlyPayments.value - monthlyPayments) * productTermMonths
  const equity = 100 - (borrowingAmount.value / propertyValue.value) * 100

  return {
    potentialMonthlyPayments: mkPounds(monthlyPayments),
    savingsOverProductTerm: mkPounds(savingsOverProductTerm),
    equity: mkPercentage(equity),
  }
}

export interface StampDutyRequest {
  isFirstTimeBuyer: boolean
  propertyValue: Pence
  country: Country
}

export interface StampDutyResponse {
  stampDutyPost1stOctober2021: Pence
}

export const getStampDuty = async (
  requestData: StampDutyRequest
): Promise<StampDutyResponse> => {
  const { isFirstTimeBuyer, propertyValue, country } = requestData

  const ftbString = `${isFirstTimeBuyer ? "" : "Not"}FirstTimeBuyer`
  const region =
    country === "England" || country === "Northern Ireland"
      ? "EnglandOrNorthernIreland"
      : country

  const res = await fetch(
    `/api/calculator/v3/stamp-duty/${ftbString}/${propertyValue.value}/${region}`,
    {
      headers: { Authorization: calculatorApiToken },
    }
  )

  if (res.status !== 200) {
    const body = await res.text()
    throw new Error(
      "Error calling /api/calculator/v3/stamp-duty: " + res.status + "; " + body
    )
  }

  const { stampDutyPost1stOctober2021 } = await res.json()
  return {
    stampDutyPost1stOctober2021: mkPence(stampDutyPost1stOctober2021),
  }
}
