export type Nullable<T> = T | null

export type MapNullable<T> = {
  readonly [K in keyof T]: Nullable<T[K]>
}

// recursive only into the top level object
export type RecursiveNullable<T> =
  // we need to special case on `T extends never` because
  // `never extends object` so instead of getting `never | null` we just get
  // `never` due to `MapNullable<never> = never`
  // see also the Footnote "never hack" at the bottom of this file
  [T] extends [never]
    ? Nullable<T>
    : T extends object
      ? MapNullable<T>
      : Nullable<T>

export type MapNonNullable<T> = {
  readonly [K in keyof T]: NonNullable<T[K]>
}

// recursive only into the top level object
export type RecursiveNonNullable<T> =
  // like RecursiveNullable, we need to special case on `T extends never`
  // because we expect 'RecursiveNonNullable<T>' to be assignable to
  // 'RecursiveNullable<T>'; and it complains if you leave it out.
  // see also the Footnote "never hack" at the bottom of this file
  [T] extends [never]
    ? NonNullable<T>
    : T extends object
      ? MapNonNullable<T>
      : NonNullable<T>

// Persuade TS that something is indeed an object
const isObject = (o: any): o is object => o && typeof o === "object"

// fully recursive into arbitrary levels of object but
// might reject values that should be fine according
// to the types involved
export const valueIsNonNull = <T>(
  value: RecursiveNullable<T>
): value is RecursiveNonNullable<T> => {
  if (isObject(value)) {
    return Object.values(value).every(valueIsNonNull)
  } else {
    return value !== null
  }
}

export const castNonNull = <T>(value: RecursiveNonNullable<T>): T => value

// Footnote "never hack"
// ---------------------
// In order to branch on a type variable `T` being `never`,
// you might, as I did, expect to be able to do:
//
//   P<T> = T extends never ? true : false
//
// However, `P<never>` ends up being `never`.
// Even though the following is `true`:
//
//   never extends never ? true : false
//
// So the "hack" is to wrap both sides in a singleton array
// and then we get the behaviour we expect...
//
//   P<T> = [t] extends [never] ? true : false
//
// Which resolves:
// * `P<never>` to `true`
// * `P<unknown>` to `false`
