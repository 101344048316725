import { ReactElement, ReactNode, useEffect, useState } from "react"
import { RecursiveNullable, valueIsNonNull } from "./nullable"
import { entries, keys } from "./utils"

/**
 * Here's how it works. First, the user creates an Answers type, like this, to
 * describe the shape of their data:
 *
 * {
 *   fieldA: string
 *   fieldB: number
 * }
 *
 * They then provide us a FormSpec of the same shape, which is a specification
 * object that contains rules for each field's behaviour.
 *
 * - `toError`: based on its current value, is the field in an error state?
 * - `isVisible`: based on the overall form state, should this field be visible
 *      to the user (and thus taken into account when deciding if the form is
 *      valid)?
 * - `defaultValue`: if this field's data is a special shape, like a nested
 *      object, the user can provide a default value; otherwise it will be null
 */
export type FormSpec<Answers> = {
  [K in keyof Answers]: {
    toError: (x: RecursiveNullable<Answers[K]>) => string | null
    isVisible?: (f: RecursiveNullable<Answers>) => boolean
    defaultValue?: RecursiveNullable<Answers[K]>
  }
}

/**
 * Internally, we keep track of the field values and errors using a FormState
 * object.
 */
export type FormState<Answers> = {
  [K in keyof Answers]: {
    value: RecursiveNullable<Answers[K]>
    error: string | null
  }
}

/**
 * The initial FormState is built using the FormSpec the user provided; we also
 * take into account any previous state the user might have persisted (e.g. if
 * they reload the page).
 */
const mkInitialFormState = <Answers extends {}>(
  formSpec: FormSpec<Answers>,
  maybePreviousState: FormState<Answers> | null
): FormState<Answers> => {
  return keys(formSpec).reduce((acc, k) => {
    const prev = maybePreviousState && maybePreviousState[k]

    // Use the previous value if there is one; or else the default value if
    // there is one; or else null
    const value = prev
      ? prev.value
      : formSpec[k].defaultValue !== undefined
      ? formSpec[k].defaultValue
      : null

    // If we have a value then we can calculate whether there's an error for it;
    // if not, the answer is missing so we show a default error for that. Note
    // that `false` may be a valid answer to a boolean question, so we need to
    // check for `undefined` and `null` explicitly
    const error =
      value !== undefined && value !== null
        ? formSpec[k].toError(value)
        : "Please answer the question"

    return { ...acc, [k]: { value, error } }
  }, {} as FormState<Answers>)
}

/**
 * Use this to take partial answers and add them to the default values of
 * the form spec.
 * It is expected this is to be done just before the FormSpec is to be
 * converted into the FormState.
 */
const prepopulateFormSpec = <Answers extends {}>(
  formSpec: FormSpec<Answers>,
  prepopulationAnswers: Partial<Answers>
): FormSpec<Answers> => {
  return keys(formSpec).reduce(
    (acc, key) => ({
      ...acc,
      [key]: {
        ...formSpec[key],
        defaultValue:
          prepopulationAnswers[key] !== undefined &&
          prepopulationAnswers[key] !== null
            ? prepopulationAnswers[key]
            : formSpec[key].defaultValue,
      },
    }),
    {} as FormSpec<Answers>
  )
}

/**
 * When the time comes to render the data, we provide the user with a
 * RenderData object. It only contains data about visible fields. For each of
 * those, we provide the current value, an error if there is one, and an
 * `onChange` function for when the field's input updates.
 */
export type RenderData<Answers> = {
  [K in keyof Answers]?: {
    value: RecursiveNullable<Answers[K]>
    error?: string
    onChange: (a: RecursiveNullable<Answers[K]>) => void
  }
}

/**
 * To provide the RenderData we need to know which fields are visible.
 * Visibility is a function of the overall form state; to calculate it, we need
 * to get all the answers so far.
 */
const getNullableAnswers = <Answers extends {}>(
  form: FormState<Answers>
): RecursiveNullable<Answers> => {
  return entries(form).reduce(
    (acc, [k, v]) => ({
      ...acc,
      [k]: v.value,
    }),
    {} as RecursiveNullable<Answers>
  )
}

/**
 * When the form is submitted, we need to provide an object with the final
 * answers. To do that we have to check that all the visible fields have been
 * answered and are valid. If any of them fail this check, we return null
 * overall.
 */
const extractFormAnswers = <Answers extends {}>(
  form: Partial<FormState<Answers>>
): Partial<Answers> | null => {
  return entries(form).reduce(
    (acc, [k, v]) => {
      if (!v) {
        return acc
      }

      if (acc === null || v.error) {
        return null
      }

      if (valueIsNonNull(v.value)) {
        return { ...acc, [k]: v.value }
      } else {
        return null
      }
    },
    {} as Answers | null
  )
}

/**
 * This is the main component that users interact with. The FormSpec and any
 * prior FormState are provided as props, along with other settings.
 *
 * We use the renderProps technique to provide the RenderData back to the user
 * so they can actually render the form inputs, along with the extractAnswers
 * function for when the form is submitted.
 */
interface FormProps<Answers> {
  formSpec: FormSpec<Answers>
  previousState: FormState<Answers> | null
  prepopulationAnswers?: Partial<Answers>
  showErrors: boolean
  onChange?: (f: FormState<Answers>) => void
  children: (
    r: RenderData<Answers>,
    e: () => Partial<Answers> | null
  ) => ReactNode
}
export const FormProvider = <Answers extends {}>({
  formSpec,
  previousState,
  prepopulationAnswers,
  showErrors,
  onChange,
  children,
}: FormProps<Answers>): ReactElement => {
  if (previousState === null && prepopulationAnswers) {
    formSpec = prepopulateFormSpec(formSpec, prepopulationAnswers)
  }

  const [state, setState] = useState<FormState<Answers>>(
    mkInitialFormState(formSpec, previousState)
  )

  useEffect(() => {
    if (onChange) {
      onChange(state)
    }
  }, [state])

  const nullableAnswers = getNullableAnswers(state)

  const renderable: RenderData<Answers> = entries(state).reduce(
    (acc, [k, v]) => {
      const oldValue = state[k].value
      const onChange = (value: typeof oldValue): void => {
        const error = formSpec[k].toError(value)
        setState({ ...state, [k]: { value, error } })
      }

      const visibleFn = formSpec[k].isVisible
      const visible = visibleFn ? visibleFn(nullableAnswers) : true
      if (visible) {
        return {
          ...acc,
          [k]: { ...v, error: showErrors ? v.error : undefined, onChange },
        }
      } else {
        return { ...acc, [k]: undefined }
      }
    },
    {}
  )

  const visibleState: Partial<FormState<Answers>> = entries(state).reduce(
    (acc, [k, v]) => {
      const visibleFn = formSpec[k].isVisible
      const visible = visibleFn ? visibleFn(nullableAnswers) : true
      return visible ? { ...acc, [k]: v } : acc
    },
    {}
  )

  return (
    <form
      onSubmit={e => {
        e.preventDefault()
      }}
    >
      {children(renderable, () => extractFormAnswers(visibleState))}
    </form>
  )
}
