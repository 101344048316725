import { useEffect, useState } from "react"

import { useSentry } from "@heyhabito/sentry"
import * as RD from "@heyhabito/remote-data"

import { getMortgageDeals } from "../../../shared-components/Calculators/api"
import {
  BuyerType,
  InitialPeriod,
  mkYears,
  MortgageDeal,
  MortgageType,
  Ordering,
  poundsToPence,
  RateType,
  RepaymentMethod,
} from "../../../shared-components/Calculators/types"

import { useAmplitude } from "../../Shared/utils/amplitude"

import {
  FormErrors,
  NewMortgageBorrowingAmounts,
  NewMortgageForm,
  NewMortgageFormInputNames,
} from "../types"
import { logUndefinedFields } from "../utils/forms"

import { useNewMortgageFormValidation } from "./useNewMortgageFormValidation"

interface NewMortgageFormData {
  formState: NewMortgageForm
  onInputChange: (value: string, name?: NewMortgageFormInputNames) => void
  formErrors: FormErrors
  dealsRequest: RD.RemoteData<Error, MortgageDeal[]>
  onSubmit: () => void
}

const initialFormState = {
  [NewMortgageFormInputNames.RepaymentType]: RepaymentMethod.CapitalAndInterest,
  [NewMortgageFormInputNames.RateType]: RateType.Fixed,
  [NewMortgageFormInputNames.InitialPeriod]: "2" as InitialPeriod,
  [NewMortgageFormInputNames.MortgageTerm]: "25",
  [NewMortgageFormInputNames.SortBy]: Ordering.OverallCostAsc,
}

export const useNewMortgageForm = (
  borrowingAmounts: NewMortgageBorrowingAmounts,
  scrollToCb: () => void
): NewMortgageFormData => {
  const { logToSentry } = useSentry()
  const { logSubmittedForm } = useAmplitude(
    BuyerType.NewMortgage,
    "deals_section"
  )

  const [formState, setFormState] = useState<NewMortgageForm>(initialFormState)
  const [activeInput, setActiveInput] = useState<NewMortgageFormInputNames>()

  const [dealsRequest, setDealsRequest] = useState<
    RD.RemoteData<Error, MortgageDeal[]>
  >(RD.NotAsked)

  useEffect(() => {
    fetchDeals()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [borrowingAmounts])

  useEffect(() => {
    onSubmit()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.sortBy])

  const formErrors = useNewMortgageFormValidation(formState, activeInput)

  const onInputChange = (
    value: string,
    name?: NewMortgageFormInputNames
  ): void => {
    if (name) {
      if (activeInput !== name) {
        setActiveInput(name)
      }

      setFormState(formState => ({
        ...formState,
        [name]: value,
      }))
    }
  }

  const fetchDeals = async (): Promise<void> => {
    setDealsRequest(RD.Loading)

    const { repaymentType, rateType, initialPeriod, mortgageTerm, sortBy } =
      formState

    try {
      const parsedRateType =
        rateType === "Fixed" ? RateType.Fixed : RateType.Variable

      const initialProduct = `${initialPeriod}${rateType}`

      const parsedMortgageTerm = parseInt(mortgageTerm, 10)

      const requestData = {
        buyerType: BuyerType.NewMortgage,
        repaymentType,
        mortgageType: MortgageType.Residential,
        initialProduct,
        rateType: parsedRateType,
        initialPeriod,
        sortBy,
        borrowingAmount: poundsToPence(
          borrowingAmounts.maxBorrowingAmountForApi
        ),
        propertyValue: poundsToPence(borrowingAmounts.maxPropertyValue),
        mortgageTerm: mkYears(parsedMortgageTerm),
      }

      const deals = await getMortgageDeals(requestData)

      setDealsRequest(RD.Success(deals))
    } catch (e) {
      const err = e instanceof Error ? e : new Error(`${e}`)
      setDealsRequest(RD.Failure(err))
      logToSentry("Error fetching mortgage deals in NewMo DealsSection", err)
    }
  }

  const onSubmit = (): void => {
    if (!formState) return

    const { repaymentType, rateType, initialPeriod, mortgageTerm, sortBy } =
      formState

    if (
      repaymentType !== undefined &&
      rateType !== undefined &&
      initialPeriod !== undefined &&
      mortgageTerm !== undefined &&
      sortBy
    ) {
      const parsedMortgageTerm = parseInt(mortgageTerm, 10)

      fetchDeals()

      logSubmittedForm({
        repaymentType,
        rateType,
        initialPeriod,
        mortgageTerm: parsedMortgageTerm,
        sortBy,
      })

      scrollToCb()
    } else {
      logUndefinedFields(
        logToSentry,
        "Error submitting form in Remo PaymentsSection",
        {
          repaymentType,
          rateType,
          initialPeriod,
          mortgageTerm,
          sortBy,
        }
      )
    }
  }

  return {
    formState,
    onInputChange,
    formErrors,
    dealsRequest,
    onSubmit,
  }
}
